import { gql } from '@apollo/client'

export const LIST_ALL_TRANSACTION = gql`
  query listAllTransactions(
    $offset: Int!
    $limit: Int!
    $type: String!
    $year: Int
    $month: Int
    $orderBy: String
    $order: String
    $categoryIds: [String]
    $search: String
    $toValidateOnly: Boolean
    $ignored: Boolean
  ) {
    allTransactions(
      offset: $offset
      limit: $limit
      type: $type
      year: $year
      month: $month
      orderBy: $orderBy
      order: $order
      categoryIds: $categoryIds
      search: $search
      toValidateOnly: $toValidateOnly
      ignored: $ignored
    ) {
      total
      totalAmount
      transactions {
        id
        type
        originalAmount
        amount
        description
        note
        originalDate
        date
        vatRate
        categoryId
        ignored
        isVatPayment
        validated
        receiptCount
        isReconciliated
        originalTransactionId
        refundTransactionId
        refundedTransactionId
        accountId
        ignoredReason
      }
    }
  }
`

export const LIST_TRANSACTIONS_FOR_REFUND = gql`
  query listAllTransactions(
    $type: String!
    $search: String
    $limit: Int!
    $exactAmountToMatchFirst: Float!
  ) {
    allTransactions(
      type: $type 
      search: $search
      limit: $limit
      offset: 0
      exactAmountToMatchFirst: $exactAmountToMatchFirst
    ) {
      total
      transactions {
        id
        type
        amount
        description
        date
        categoryId
        refundTransactionId
        refundedTransactionId
        originalTransactionId
      }
    }
  }
`

export const LIST_SIMILAR_UNCATEGORIZED_TRANSACTIONS = gql`
  query listAllTransactions(
    $type: String!
    $categoryIds: [String]
    $vatRates: [Float]
    $search: String!
  ) {
    allTransactions(
      type: $type
      categoryIds: $categoryIds
      vatRates: $vatRates
      search: $search
      filterSearch: true
    ) {
      filteredSearch
      transactions {
        id
      }
    }
  }
`

export const REFRESH_TRANSACTION_RECONCILIATION_STATE = gql`
  query getTransaction(
    $id: ID!
  ) {
    getTransaction(id: $id) {
      id
      isReconciliated
    }
  }
`

export const BATCH_UPDATE_TRANSACTIONS = gql`
  mutation batchUpdateTransactions(
    $ids: [ID!]!
    $properties: BatchUpdateProperties!
  ) {
    batchUpdateTransactions(
      ids: $ids
      properties: $properties
    ) {
      transactions {
        id
        vatRate
        categoryId
        validated
        ignored
      }
      actionsLeft {
        category
      }
    }
  }
`

export const SPLIT_TRANSACTION = gql`
  mutation splitTransaction(
    $input: SplitTransactionInput!
  ) {
    splitTransaction(
      input: $input
    ) {
      originalTransaction {
        id
        splitted
      }
    }
  }
`

export const GET_SPLITTED_TRANSACTION_WITH_PARTS = gql`
  query getSplittedTransactionWithParts($originalTransactionId: ID!) {
    getSplittedTransactionWithParts(
      originalTransactionId: $originalTransactionId
    ) {
      originalTransaction {
        id
        type
        description
        date
        amount
        receiptCount
        isReconciliated
      }
      transactionParts {
        id
        type
        description
        date
        amount
        currency
        categoryId
        vatRate
        ignored
        validated
      }
    }
  }
`

export const UNSPLIT_TRANSACTION = gql`
  mutation unsplitTransaction($originalTransactionId: ID!) {
    unsplitTransaction(originalTransactionId: $originalTransactionId) {
      originalTransaction {
        id
        splitted
      }
    }
  }
`

export const SHIFT_TRANSACTION_DATE = gql`
  mutation shiftTransactionDate($id: ID!, $date: Date!) {
    shiftTransactionDate(id: $id, date: $date) {
      id
      date
    }
  }
`

export const ANNOTATE_TRANSACTION = gql`
  mutation annotateTransaction($id: ID!, $note: String!) {
    annotateTransaction(id: $id, note: $note) {
      id
      note
    }
  }
`

export const MARK_TRANSACTION_AS_REFUND = gql`
  mutation markTransactionAsRefund($refundTransactionId: ID!, $refundedTransactionId: ID!) {
    markTransactionAsRefund(refundTransactionId: $refundTransactionId, refundedTransactionId: $refundedTransactionId) {
      refundTransaction {
        id
        originalAmount
        amount
        refundedTransactionId
      }
      refundedTransaction {
        id
        originalAmount
        amount
        refundTransactionId
      }
    }
  }
`

export const CANCEL_TRANSACTION_REFUND = gql`
  mutation cancelTransactionRefund($refundTransactionId: ID!) {
    cancelTransactionRefund(refundTransactionId: $refundTransactionId) {
      refundTransaction {
        id
        originalAmount
        amount
        refundedTransactionId
      }
      refundedTransaction {
        id
        originalAmount
        amount
        refundTransactionId
      }
    }
  }
`

export const MARK_TRANSACTION_AS_VAT_PAYMENT = gql`
  mutation markTransactionAsVatPayment($id: ID!) {
    markTransactionAsVatPayment(id: $id) {
      id
      isVatPayment
      vatRate
    }
  }
`

export const UNMARK_TRANSACTION_AS_VAT_PAYMENT = gql`
  mutation unmarkTransactionAsVatPayment($id: ID!) {
    unmarkTransactionAsVatPayment(id: $id) {
      id
      isVatPayment
      vatRate
    }
  }
`

export const EXPORT_TRANSACTIONS = gql`
query exportTransactions($fromDate: Date!, $toDate: Date!, $type: String, $cashinCategoryIds: [String!], $cashoutCategoryIds: [String!], $format: String!) {
  exportTransactions(fromDate: $fromDate, toDate: $toDate, type: $type, cashinCategoryIds: $cashinCategoryIds, cashoutCategoryIds: $cashoutCategoryIds, format: $format ) {
    content
    fileName
    mimeType
    stringFormat
    transactionsCount
  }
}
`

export const GET_QUOTES_COUNT = gql`
  query getQuotesCount {
    getQuotesCount
  }
`

export const CATEGORIZE_TRANSACTION = gql`
  mutation categorizeTransaction($id: ID!, $categoryId: ID!) {
    categorizeTransaction(id: $id, categoryId: $categoryId) {
      transaction {
        id
        categoryId
        vatRate
        vatAmount
      }
      actionsLeft {
        category
      }
    }
  }
`

export const UNCATEGORIZE_TRANSACTION = gql`
  mutation uncategorizeTransaction($id: ID!) {
    uncategorizeTransaction(id: $id) {
      transaction {
        id
        categoryId
      }
      actionsLeft {
        category
      }
    }
  }
`

export const VALIDATE_TRANSACTION = gql`
  mutation validateTransaction($id: ID!) {
    validateTransaction(id: $id) {
      id
      validated
    }
  }
`

export const UNVALIDATE_TRANSACTION = gql`
  mutation unvalidateTransaction($id: ID!) {
    unvalidateTransaction(id: $id) {
      id
      validated
    }
  }
`

export const IGNORE_TRANSACTION = gql`
  mutation ignoreTransaction($id: ID!, $ignoredReason: IgnoredReason) {
    ignoreTransaction(id: $id, ignoredReason: $ignoredReason) {
      id
      ignored
      ignoredReason
    }
  }
`

export const UNIGNORE_TRANSACTION = gql`
  mutation unignoreTransaction($id: ID!) {
    unignoreTransaction(id: $id) {
      id
      ignored
      ignoredReason
    }
  }
`

export const CHANGE_TRANSACTION_VAT_RATE = gql`
  mutation changeTransactionVatRate($id: ID!, $vatRate: Float!) {
    changeTransactionVatRate(id: $id, vatRate: $vatRate) {
      id
      vatRate
    }
  }
`

export const IMPORT_TRANSACTIONS_FROM_UPLOAD = gql`
  mutation importTransactionsFromUpload($accountId: ID!, $transactionsParams: [TransactionParamInput]!) {
    importTransactionsFromUpload(accountId: $accountId, transactionsParams: $transactionsParams) {
      importTransactionsResult {
        recordId
        imported
        error
        errorMessage
        errorField
      }
    }
  }
`
