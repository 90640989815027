import { useLazyQuery, useMutation } from '@apollo/client'
import { Flatfile, PartialRejection, RecordError } from '@flatfile/sdk'
import { GET_INVOICE_PORTAL_TOKEN } from 'graphql/importers'
import { IMPORT_INVOICE_FROM_UPLOAD } from 'graphql/invoices'
import { get, last, toNumber } from 'lodash'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { convertStringDateFrToIso } from 'utils/dates'
import flatfileConfig from 'config/flatfile.js'

export default function useInvoiceImporter ({ type, refetchInvoices }) {
  const { t } = useTranslation()
  const [getInvoicePortalToken] = useLazyQuery(GET_INVOICE_PORTAL_TOKEN)
  const [importInvoiceFromUpload] = useMutation(IMPORT_INVOICE_FROM_UPLOAD)

  const getToken = useCallback(async () => {
    const { data: { getInvoicePortalToken: { token } } } = await getInvoicePortalToken()
    return token
  }, [getInvoicePortalToken])

  const importChunk = useCallback(async (chunk, next) => {
    const externalImportId = chunk.session.meta.batchId
    const records = chunk.records

    const rejections = []

    // for (let index = 0; index < records.length; index++) {
    // const record = records[index]
    const record = records[0]
    const data = record.data

    try {
      await importInvoiceFromUpload({
        variables: {
          input: {
            ...data,
            amount: toNumber(data.amount),
            amountExcludingVat: toNumber(data.amountExcludingVat),
            issueDate: convertStringDateFrToIso(data.issueDate),
            dueDate: convertStringDateFrToIso(data.dueDate),
            expectedDate: convertStringDateFrToIso(data.expectedDate),
            type,
            externalImportId
          }
        }
      })
    } catch (error) {
      const errorCode = get(error, 'graphQLErrors.0.extensions.code')
      const isValidationError = get(error, 'graphQLErrors.0.message') === 'Validation'

      if (isValidationError) {
        const validationErrors = get(error, 'graphQLErrors.0.extensions.details', [])

        const rejectionValidationsErrors = []

        validationErrors.forEach((validationError) => {
          const field = last(validationError?.path)
          const message = validationError?.message

          const hasFieldDefined = Object.prototype.hasOwnProperty.call(data, field)
          if (hasFieldDefined) rejectionValidationsErrors.push({ field, message })
        })

        rejections.push(new RecordError(record.recordId, rejectionValidationsErrors))
      } else if (errorCode === 'INVOICE_ALREADY_EXISTS') {
        rejections.push(
          new RecordError(record.recordId, [
            {
              field: 'invoiceNumber',
              message: t('hook.useInvoiceImporter.error.INVOICE_ALREADY_EXISTS', { cashflowType: (type === 'cashin' ? t('shared.cashin') : t('shared.cashout'))?.toLowerCase() })
            }
          ])
        )
      } else {
        rejections.push(
          new RecordError(record.recordId, [
            {
              field: 'invoiceNumber',
              message: t('hook.useInvoiceImporter.error.unknownErrorWhileImporting', { cashflowType: (type === 'cashin' ? t('shared.cashin') : t('shared.cashout'))?.toLowerCase() })
            }
          ])
        )
      }
    }
    // }

    next(new PartialRejection(rejections))
  }, [importInvoiceFromUpload, t, type])

  const triggerImport = useCallback(async () => {
    await Flatfile.requestDataFromUser({
      ...flatfileConfig,
      chunkSize: 1,
      token: getToken,
      onData: importChunk,
      onComplete: refetchInvoices,
      onError: console.warn
    })
  }, [getToken, importChunk, refetchInvoices])

  return { triggerImport }
}
